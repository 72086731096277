<template>
  <div class="ng-content-wrap">
    <div class="ng-block-head ng-block-head-lg">
      <div class="ng-block-head-sub d-print-none">
        <router-link class="back-to" to="/">
          <em class="icon ni ni-chevron-left-c mr-1"></em>
          <span>Dashboard</span>
        </router-link>
      </div>
      <div class="ng-block-between-md g-4">
        <div class="ng-block-head-content">
          <h2 class="ng-block-title fw-normal">
            {{ $route.meta.title }}
          </h2>
          <div class="ng-block-des d-print-none">
            <p>Configure App Settings</p>
          </div>
        </div>
      </div>
    </div>

    <div class="ng-block">
      <div class="card">
        <!-- Tabs -->
        <div class="card-body">
          <!-- Nav tabs -->
          <ul class="nav nav-tabs" id="myTab" role="tablist">
            <li class="nav-item">
              <a
                class="nav-link active"
                id="forex-settings-tab"
                data-toggle="tab"
                href="#forex-settings"
                role="tab"
                aria-controls="forex-settings"
                aria-selected="true"
              >
                Exchange
                <span class="d-none d-sm-inline pl-1">
                  Rate
                </span>
              </a>
            </li>
            <li class="nav-item">
              <a
                class="nav-link"
                id="wage-settings-tab"
                data-toggle="tab"
                href="#wage-settings"
                role="tab"
                aria-controls="wage-settings"
                aria-selected="false"
              >
                <span class="d-none d-sm-inline pr-1">
                  Daily
                </span>
                Wages
              </a>
            </li>
            <li class="nav-item">
              <a
                class="nav-link"
                id="finance-settings-tab"
                data-toggle="tab"
                href="#finance-settings"
                role="tab"
                aria-controls="finance-settings"
                aria-selected="false"
              >
                Finance
                <span class="d-none d-sm-inline pl-1">
                  Settings
                </span>
              </a>
            </li>
            <li class="nav-item">
              <a
                class="nav-link"
                id="reg-settings-tab"
                data-toggle="tab"
                href="#reg-settings"
                role="tab"
                aria-controls="reg-settings"
                aria-selected="false"
              >
                <span class="d-none d-sm-inline">
                  Registration & Contacts
                </span>
                <i class="ni ni-account-plus"></i>
              </a>
            </li>
          </ul>
          <div class="tab-content" id="myTabContent">
            <div
              class="tab-pane fade show active p-20"
              id="forex-settings"
              role="tabpanel"
              aria-labelledby="forex-settings-tab"
            >
              <form
                class="col-md-6"
                @submit.prevent="updateSettings"
                method="POST"
              >
                <input type="hidden" name="option" value="forex" />
                <div class="form-group">
                  <label class="control-label">
                    KSH-GBP Rate
                  </label>
                  <input
                    id="exchange_rate"
                    type="text"
                    placeholder="Enter "
                    name="value[rate]"
                    v-model="settings.forex.rate.pound"
                    class="form-control rounded form-control rounded-line"
                  />
                </div>
                <div class="form-group">
                  <label class="control-label">
                    KSH-EUR Rate
                  </label>
                  <input
                    id="exchange_rate"
                    type="text"
                    placeholder="Enter "
                    name="value[rate]"
                    v-model="settings.forex.rate.euro"
                    class="form-control rounded form-control rounded-line"
                  />
                </div>
                <div class="form-group">
                  <label class="control-label">
                    KSH-USD Rate
                  </label>
                  <input
                    id="exchange_rate"
                    type="text"
                    placeholder="Enter "
                    name="value[rate]"
                    v-model="settings.forex.rate.dollar"
                    class="form-control rounded form-control rounded-line"
                  />
                </div>
                <div class="form-group">
                  <div class="col-sm-12">
                    <button class="btn btn-primary btn-rounded" type="submit">
                      Update Settings
                    </button>
                  </div>
                </div>
              </form>
            </div>
            <div
              class="tab-pane fade p-20"
              id="wage-settings"
              role="tabpanel"
              aria-labelledby="wage-settings-tab"
            >
              <form
                class="col-md-6"
                @submit.prevent="updateSettings"
                method="POST"
              >
                <input type="hidden" name="option" value="wages" />
                <div class="form-group">
                  <label class="control-label">
                    Daily Wage
                  </label>
                  <input
                    type="text"
                    placeholder="Enter "
                    name="value[rate]"
                    v-model="settings.wages.rate"
                    class="form-control rounded form-control rounded-line"
                  />
                </div>
                <div class="form-group">
                  <div class="col-sm-12">
                    <button class="btn btn-primary btn-rounded" type="submit">
                      Update Settings
                    </button>
                  </div>
                </div>
              </form>
            </div>
            <div
              class="tab-pane fade p-20"
              id="finance-settings"
              role="tabpanel"
              aria-labelledby="finance-settings-tab"
            >
              <form
                class="col-md-6"
                @submit.prevent="updateSettings"
                method="POST"
              >
                <div class="form-group">
                  <label class="control-label"
                    >
                    Monthly Interest Charge (KSh)
                    </label
                  >
                  <input
                    type="text"
                    placeholder="Enter charge"
                    name="value[interest]"
                    v-model="settings.finance.interest"
                    class="form-control rounded form-control rounded-line"
                  />
                </div>
                <div class="form-group">
                  <label class="control-label"
                    >
                    Monthly Export Tonnage (Kg)
                    </label
                  >
                  <input
                    type="text"
                    placeholder="Enter tonnage"
                    name="value[tonnage]"
                    v-model="settings.finance.tonnage"
                    class="form-control rounded form-control rounded-line"
                  />
                </div>
                <div class="form-group">
                  <div class="col-sm-12">
                    <button class="btn btn-primary btn-rounded" type="submit">
                      Update Settings
                    </button>
                  </div>
                </div>
              </form>
            </div>
            <div
              class="tab-pane fade p-20"
              id="reg-settings"
              role="tabpanel"
              aria-labelledby="reg-settings-tab"
            >
              <form
                class="form-horizontal form-material"
                @submit.prevent="updateSettings"
                method="POST"
              >
                <input type="hidden" name="option" value="reg" />
                <div class="form-group">
                  <br />
                  <div class="custom-control custom-checkbox notext">
                    <input
                      type="checkbox"
                      id="regallow"
                      name="value[allow]"
                      v-model="settings.reg.allow"
                      value="yes"
                    />
                  <label class="custom-control-label" for="regallow">Allow User Registration</label>
                  </div>
                </div>
                
                <div class="form-group">
                  <label>Email Contacts</label>
                  <div class="row mb-3" v-for="(contact, i) in settings.contacts" :key="i">
                    <div class="col-md-3">
                      <input class="form-control" v-model="contact.name" placeholder="Contact name" type="text" />
                    </div>
                    <div class="col-md-4">
                      <input class="form-control" v-model="contact.email" placeholder="Contact email" type="email" />
                    </div>
                    <div class="col-md-2">
                      <button class="btn btn-round btn-icon btn-danger" @click.prevent="delContact(i)" v-if="settings.contacts.length > 1">
                        <em class="icon ni ni-minus text-center"></em>
                      </button>
                      <button class="btn btn-round btn-icon btn-primary ml-1" @click.prevent="addContact()" v-if="i == settings.contacts.length -1">
                        <em class="icon ni ni-plus"></em>
                      </button>
                    </div>
                  </div>
                </div>

                <div class="form-group">
                  <div class="col-sm-12">
                    <button class="btn btn-primary btn-rounded" type="submit">
                      Update Settings
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, reactive, onMounted, toRefs } from "vue";

import router from "../router";
import http from "../../../../common/services/http";
import store from "../../../../common/services/store";

export default defineComponent({
  setup() {
    const settings = reactive({
      forex: {
        rate: {
          pound: 0,
          euro: 0,
          dollar: 0
        }
      },
      wages: {
        rate: 0
      },
      finance: {
        interest: 0,
        tonnage: 0
      },
      reg: {
        allow: "no"
      },
      contacts: [
        {name: '', email: ''}
      ]
    });

    const { user } = toRefs(store.state);

    function updateSettings() {
      http.post("/api/settings", settings).then((res: any) => {
        settings.forex = res.data.forex;
        settings.finance = res.data.finance;
        settings.wages = res.data.wages;
        settings.reg = res.data.reg;
        settings.contacts = res.data.contacts;
      });
    }

    function addContact() {
      settings.contacts.push(
        {name: '', email: ''}
      )
    }

    function delContact(i: any) {
      settings.contacts.splice(i, 1)
    }

    onMounted(() => {
      http.get(`/api/settings`).then((res: any) => {
        if (res.data.reg) {
          settings.forex = res.data.forex ? res.data.forex : { rate: 0 };
          settings.finance = res.data.finance
            ? res.data.finance
            : { interest: 0, tonnage: 0 };
          settings.wages = res.data.wages ? res.data.wages : { rate: 0 };
          settings.reg = res.data.reg ? res.data.reg : { allow: "no" };
          settings.contacts = res.data.contacts ? res.data.contacts : [
        {name: '', email: ''}
      ];
        }
      });

      // http
      //   .get(
      //     "https://free.currconv.com/api/v7/convert?q=GBP_KES&compact=ultra&apiKey=53ca78889a1144945a77"
      //   )
      //   .then((res: any) => {
      //     if (res.data.GBP_KES) {
      //       const rate = res.data.GBP_KES;
      //       settings.forex.rate = rate.toFixed(2);
      //     }
      //   });
    });

    return {
      user,
      settings,
      addContact,
      delContact,
      updateSettings
    };
  }
});
</script>
