
import { defineComponent, reactive, onMounted, toRefs } from "vue";

import router from "../router";
import http from "../../../../common/services/http";
import store from "../../../../common/services/store";

export default defineComponent({
  setup() {
    const settings = reactive({
      forex: {
        rate: {
          pound: 0,
          euro: 0,
          dollar: 0
        }
      },
      wages: {
        rate: 0
      },
      finance: {
        interest: 0,
        tonnage: 0
      },
      reg: {
        allow: "no"
      },
      contacts: [
        {name: '', email: ''}
      ]
    });

    const { user } = toRefs(store.state);

    function updateSettings() {
      http.post("/api/settings", settings).then((res: any) => {
        settings.forex = res.data.forex;
        settings.finance = res.data.finance;
        settings.wages = res.data.wages;
        settings.reg = res.data.reg;
        settings.contacts = res.data.contacts;
      });
    }

    function addContact() {
      settings.contacts.push(
        {name: '', email: ''}
      )
    }

    function delContact(i: any) {
      settings.contacts.splice(i, 1)
    }

    onMounted(() => {
      http.get(`/api/settings`).then((res: any) => {
        if (res.data.reg) {
          settings.forex = res.data.forex ? res.data.forex : { rate: 0 };
          settings.finance = res.data.finance
            ? res.data.finance
            : { interest: 0, tonnage: 0 };
          settings.wages = res.data.wages ? res.data.wages : { rate: 0 };
          settings.reg = res.data.reg ? res.data.reg : { allow: "no" };
          settings.contacts = res.data.contacts ? res.data.contacts : [
        {name: '', email: ''}
      ];
        }
      });

      // http
      //   .get(
      //     "https://free.currconv.com/api/v7/convert?q=GBP_KES&compact=ultra&apiKey=53ca78889a1144945a77"
      //   )
      //   .then((res: any) => {
      //     if (res.data.GBP_KES) {
      //       const rate = res.data.GBP_KES;
      //       settings.forex.rate = rate.toFixed(2);
      //     }
      //   });
    });

    return {
      user,
      settings,
      addContact,
      delContact,
      updateSettings
    };
  }
});
